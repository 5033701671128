import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import { NUM_COUNTRIES_IN_REG } from '../../utils/const'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
//import { wrangleSP16Data } from '../../utils/helpers'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  margin: 3rem -1.5rem 6rem;
`

const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--baseBlue);
`
const MiniChartContainer = styled.div`
  margin: 1rem 1.5rem 5rem 1.5rem;

  min-width: 300px;
`
const MiniChart = styled.figure`
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  //background-color: pink;
`

const BarCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80px;
  height: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  margin: 0 10px;
  :nth-child(1) {
    margin-left: 0;
  }
  justify-content: flex-end;
  align-items: center;
`
const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: ${({ color }) => `var(--${color})`};
  opacity: 1;
  color: var(--white);
  font-weight: bold;
  //padding-top: 0.5rem;
`

const BarPercentage = styled.div`
  position: relative;
  top: ${({ value }) => (value >= 8 ? '16px' : '-30px')};
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  margin: 0 -0.5rem;
  text-align: center;
  line-height: 1.2;
`
// const Line = styled.div`
//   width: 100%;
//   height: ${({ height }) => height};
//   border-top: ${({ color }) => `3px solid var(--${color})`};
//   position: absolute;
//   left: 0;
//   bottom: 0;
// `
// const Label = styled.div`
//   font-weight: bold;
//   font-size: 1.25rem;
//   color: ${({ color }) => `var(--${color})`};
//   width: 100%;

//   text-align: right;
//   position: absolute;
//   top: ${({ position }) => position};
// `
const SP16Chart = ({ data, region, title }) => {
  const { t } = useTranslation()
  //const formattedData = wrangleSP16Data(data)

  //antiicipating having columns for second type of data
  // const vigiData = formattedData
  //   .map((d) => (d.type === 'vigiAEFI' ? d : null))
  //   .filter((item) => item)
  // const numCountries = NUM_COUNTRIES_IN_REG[region] //number of countries
  return (
    <Container>
      <MiniChartContainer>
        <ChartTitle>{t('vigiBase data')}</ChartTitle>
        <MiniChart
          role="img"
          alt={t(
            `Bar chart showing proportion of countries with at least 1 documented individual serious AEFI case safety report per million total population`
          )}
        >
          {data.map((d, i) => (
            <BarCol key={'vigi' + d.VALUE_NUM} color="paleBlue">
              <Bar
                height={`${d.VALUE_NUM > 0 ? d.VALUE_NUM : 0}%`}
                color="brightBlue"
              >
                <BarPercentage value={d.VALUE_NUM}>
                  {`${(d.VALUE_NUM > 0 ? d.VALUE_NUM : 0).toFixed()}%`}
                </BarPercentage>
              </Bar>
              <BarLabelContainer>
                <BarLabel weight={d.YEAR === 2021 ? 'bold' : 'normal'}>
                  {d.YEAR === 2021 ? t('Baseline') : d.YEAR}
                </BarLabel>
                {/* {!i && <BarLabel weight={'bold'}>{t('Baseline')}</BarLabel>} */}
              </BarLabelContainer>
            </BarCol>
          ))}
        </MiniChart>
      </MiniChartContainer>
    </Container>
  )
}

export default SP16Chart
