import React from 'react'
import Table from '../Table'
//import { NUM_COUNTRIES_IN_REG } from '../../utils/const'
//import { wrangleSP16Data } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'

const SP16Table = ({ regions, region }) => {
  const { t } = useTranslation()
  //const numCountries = NUM_COUNTRIES_IN_REG[region] //number of countries
  // const wrangleData = (data) => {
  //   const allData = wrangleSP16Data(data)
  //   const formattedData = allData.reduce((acc, d) => {
  //     years.push(d.year)
  //     acc[d.year] = `${d.value} ${t('out of')} ${numCountries}`
  //     acc['type'] = d.type
  //     acc['year'] = d.year
  //     return acc
  //   }, {})

  //   const completeFormattedData = [
  //     Object.assign(formattedData, {
  //       label: t('proportion of countries')
  //     })
  //   ]

  //   return completeFormattedData
  // }
  const BASELINE_YEAR = 2021
  const years = []
  const wrangleData = (regions) => {
    return [
      regions.reduce((acc, d) => {
        if (d.YEAR !== BASELINE_YEAR) years.push(d.YEAR)

        acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] = `${
          d.NUMERATOR
        } ${t('out of')} ${d.DENOMINATOR}`
        acc['label'] = t('proportion of countries')
        return acc
      }, {})
    ]
  }
  const sp16Data = wrangleData(regions)
  const data = React.useMemo(() => sp16Data, [sp16Data])

  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })
  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: '',
        accessor: 'label' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that shows proportion of countries with at least 1 documented individual serious AEFI case safety report per million total population for each year.'
      )}
    />
  )
}

export default SP16Table
